import { Component } from 'nuxt-property-decorator';
import Vue from 'vue';

@Component
class SwiperMixin extends Vue {
  $refs !: { swiper: Element };
  swiperVibile: boolean = false;
  activeSlideIndex: number = 0;
  changeSlide (to: number): void {
    // @ts-ignore
    const swiper = this.$refs.swiper.swiper;
    swiper.slideTo(to);
  }
  setIndex (): void {
    // @ts-ignore
    const swiper = this.$refs.swiper.swiper;
    this.activeSlideIndex = swiper.activeIndex;
  }
  keyControls (e: KeyboardEvent): void {
    if (this.$refs.swiper && this.isInViewport(this.$refs.swiper)) {
      // @ts-ignore
      const swiper = this.$refs.swiper.swiper;
      if (e.key === 'ArrowRight') {
        swiper.slideNext();
      } else if (e.key === 'ArrowLeft') {
        swiper.slidePrev();
      }
    }
  }
  isInViewport (element: Element): boolean {
    if (element) {
      const el = element.getBoundingClientRect();
      return (
        el.top >= 0 &&
        el.bottom <= window.innerHeight
      );
    } else {
      return false;
    }
  }
  mounted () {
    if (!process.browser) { return; }
    document.addEventListener('keydown', (e) => {
      this.keyControls(e);
    });
    this.swiperVibile = true;
  }
}
export default SwiperMixin;
