
























import { Vue, Component, Prop } from 'nuxt-property-decorator';

@Component
export default class SwiperNavigation extends Vue {
  @Prop() active_slide_index!: number;
  @Prop() number_of_slides!: number;
  icon: string = require('~/assets/icons/arrow_reduced.svg');
}
