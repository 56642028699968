































import { Vue, Component, Prop, mixins, Watch } from 'nuxt-property-decorator';
import { BlokContentSlider } from '../../types';
import SwiperMixin from '../../mixins/SwiperMixin';
import SwiperNavigation from '@/components/elements/SwiperNavigation';

@Component({
  components: { SwiperNavigation }
})
export default class ContentSlider extends mixins(SwiperMixin) {
  @Prop() blok!: BlokContentSlider;
  autoplayState: boolean = false;
  swiperOptions: {} = {
    // ---smaller than 900px
    slidesPerView: 1,
    centeredSlidesBounds: true,
    centeredSlides: true,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
    spaceBetween: 50,
    initialSlide: 0,
    grabCursor: true,
    loop: false,
    autoplay: false,
    breakpoints: {
      // ---bigger than 900px
      900: {
        centeredSlides: true,
        centeredSlidesBounds: false
      }
    }
  };

  activateSwiperAutoplay () {
    if (this.$refs.swiper?.swiper) {
      this.$refs.swiper.swiper.params.autoplay = {
        delay: 6000,
        disableOnInteraction: false
      };
      this.$refs.swiper.swiper.autoplay.start();
    }
  }

  deactivateSwiperAutoplay () {
    if (this.$refs.swiper?.swiper) {
      this.$refs.swiper.swiper.autoplay.stop();
    }
  }
}
